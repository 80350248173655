import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { RouterFacade } from '@core/shared/data-access';
import { PaginationMetadata } from '@core/shared/domain';
import { NotificationService, filterNull } from '@core/shared/util';
import { PagedDataFetcher, PagedDataSource } from '@core/ui';
import { CLINIC_SUPPLIER_ROUTE_PARAM_MAP_KEY } from '@mp/supplier-management/clinic-suppliers/util';
import { ClinicSupplier, ClinicSupplierBasic, UpdateClinicSupplier } from '@mp/supplier-management/common/domain';

import { ClinicSupplierService } from './clinic-supplier.service';

type FetchParams = { searchTerm: string };

@Injectable()
export class ClinicSupplierFacade {
  readonly id$: Observable<string>;

  private readonly _errors$: BehaviorSubject<Record<string, Array<string>> | undefined> = new BehaviorSubject(
    undefined as Record<string, Array<string>> | undefined,
  );
  readonly errors$: Observable<Record<string, Array<string>> | undefined> = this._errors$.asObservable();

  private readonly _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
  readonly isLoading$: Observable<boolean> = this._isLoading$.asObservable();

  private readonly _pagination$: BehaviorSubject<PaginationMetadata | undefined> = new BehaviorSubject(
    undefined as PaginationMetadata | undefined,
  );
  readonly pagination$: Observable<PaginationMetadata | undefined> = this._pagination$.asObservable();

  private readonly _clinicSupplier$: BehaviorSubject<ClinicSupplier | undefined> = new BehaviorSubject(
    undefined as ClinicSupplier | undefined,
  );
  readonly clinicSupplier$: Observable<ClinicSupplier | undefined> = this._clinicSupplier$.asObservable();

  private readonly _isProcessingExport$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
  readonly isProcessingExport$: Observable<boolean> = this._isProcessingExport$.asObservable();

  readonly dataSource: PagedDataSource<ClinicSupplierBasic, FetchParams>;

  constructor(
    private readonly routerFacade: RouterFacade,
    private readonly toaster: NotificationService,
    private readonly service: ClinicSupplierService,
  ) {
    this.id$ = routerFacade.routeParam$(CLINIC_SUPPLIER_ROUTE_PARAM_MAP_KEY).pipe(filterNull());

    this.dataSource = this.buildDataSource(this.service);
  }

  private buildDataSource(service: ClinicSupplierService): PagedDataSource<ClinicSupplierBasic, FetchParams> {
    const fetcher: PagedDataFetcher<ClinicSupplierBasic, FetchParams> = (pagination, { searchTerm }) =>
      service.getAll(searchTerm, pagination).pipe(
        tap(({ pagination }) => {
          this._pagination$.next(pagination);
        }),
        map(({ data, pagination }) => ({
          results: data,
          hasMore: pagination.currentPage < pagination.totalPageCount,
        })),
      );

    const initialParams = { searchTerm: '' };

    return new PagedDataSource<ClinicSupplierBasic, FetchParams>(fetcher, initialParams);
  }

  get(id: string): void {
    this._isLoading$.next(true);
    this.service.get(id).subscribe({
      next: (clinicSupplier) => {
        this._clinicSupplier$.next(clinicSupplier);
        this._isLoading$.next(false);
      },
    });
  }

  private refreshDataSource(id: string): void {
    const index = this.dataSource.data.findIndex((l) => l.supplierId === id);
    if (index > -1) {
      this.dataSource.refreshPageContaining(index).subscribe();
    } else {
      this.dataSource.reset();
      this.dataSource.fetchMore().subscribe();
    }
  }

  update(id: string, updateDto: UpdateClinicSupplier): void {
    this.service.update(id, updateDto).subscribe({
      next: (clinicSupplier) => {
        this.toaster.toastSuccess('Die Änderungen wurden gespeichert.');

        this._clinicSupplier$.next(clinicSupplier);
        this._errors$.next(undefined);

        this.refreshDataSource(clinicSupplier.supplierId);
      },
      error: (error: unknown) => this._errors$.next((error as HttpErrorResponse).error.errors),
    });
  }

  exportAll(): void {
    this._isProcessingExport$.next(true);
    this.service.exportAll().subscribe({
      next: () => {
        this._isProcessingExport$.next(false);
        this.toaster.toastSuccess(
          'Der Kliniklieferanten Export wurde per E-Mail versendet. Bitte prüfe dein Postfach.',
        );
      },
      error: () => {
        this._isProcessingExport$.next(false);
        this.toaster.toastDanger('Der Export ist leider fehlgeschlagen. Bitte versuche es nochmal.');
      },
    });
  }

  navigateBack(): void {
    this.routerFacade.navigateBack();
  }
}
