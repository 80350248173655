import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageResponse, Pagination } from '@core/shared/domain';
import { QueryParams } from '@core/shared/util';
import { ClinicSupplier, ClinicSupplierBasic, UpdateClinicSupplier } from '@mp/supplier-management/common/domain';

@Injectable()
export class ClinicSupplierService {
  private readonly baseApiPath = '/api/contentmanagement/suppliers/clinicsuppliers';

  constructor(private readonly httpClient: HttpClient) {}

  getAll(searchTerm?: string, pagination?: Partial<Pagination>): Observable<PageResponse<ClinicSupplierBasic>> {
    const params = QueryParams.build<ClinicSupplierBasic>();

    if (searchTerm) {
      params.param('searchTerm', searchTerm);
    }

    params
      .page(pagination?.page ?? 1)
      .pageSize(pagination?.pageSize ?? 250)
      .sort('name');

    return this.httpClient.get<PageResponse<ClinicSupplierBasic>>(`${this.baseApiPath}`, {
      params: params.toHttpParams(),
    });
  }

  get(id: string): Observable<ClinicSupplier> {
    return this.httpClient.get<ClinicSupplier>(`${this.baseApiPath}/${id}`);
  }

  update(id: string, dto: UpdateClinicSupplier): Observable<ClinicSupplier> {
    return this.httpClient.put<ClinicSupplier>(`${this.baseApiPath}/${id}`, dto);
  }

  exportAll(): Observable<void> {
    return this.httpClient.post<void>(`${this.baseApiPath}/export`, {});
  }
}
